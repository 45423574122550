import React, { useState } from "react";
import PopupModal from "./PopupModal";
import '@fortawesome/fontawesome-free/css/all.min.css';

function Result({ result, setShow }) {
  const [showModal, setShowModal] = useState(false);
  const [showSignUpForm, setShowSignUpForm] = useState(false);

  const handleRestartClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowSignUpForm(false);
  };

  const handleYesClick = () => {
    setShowSignUpForm(true);
  };

  const handleNoClick = () => {
    setShowModal(false);
    setShowSignUpForm(false);
    setShow(false); 
  };

  const handleCrossClick = () => {
    setShow(false); 
  };

  return (
    <div className="res-container">
      <div className="Result">
        <i className="fa-solid fa-circle-xmark close-button" onClick={handleCrossClick}></i>
        <p className="fs-3">Your score is: {result}</p>
        <button
          className="btn mt-3"
          style={{ borderRadius: "3px", backgroundColor: "rgb(252, 169, 3)" }}
          onClick={handleRestartClick}
        >
          Restart
        </button>
      </div>

      {showModal && (
        <PopupModal
          onClose={handleCloseModal}
          onYesClick={handleYesClick}
          onNoClick={handleNoClick}
          showSignUpForm={showSignUpForm}
        />
      )}
    </div>
  );
}

export default Result;
