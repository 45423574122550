import React, { useState, useEffect } from "react";
import Quiz from "./Quiz";
import Progress from "./Progress";
import Result from "./Result";
import PopupModal from "./PopupModal";
import '@fortawesome/fontawesome-free/css/all.min.css';

function QuizApp() {
  const questions = [
    {
      id: 1,
      question: "What is React?",
      options: [
        "Server-side framework",
        "User-interface framework",
        "Both",
        "None",
      ],
      answer: 2,
    },
    {
      id: 2,
      question:
        "Which of the following is used in React.js to increase performance?",
      options: ["Virtual DOM", "Original DOM", "Both", "None"],
      answer: 1,
    },
    {
      id: 3,
      question:
        "Identify the one which is used to pass data to components from outside",
      options: ["Render with arguments", "setState", "propsTypes", "props"],
      answer: 1,
    },
    {
      id: 4,
      question: "Who created React.js?",
      options: ["Jordan Mike", "Jordan Walke", "Tim Lee", "Jorden Lee"],
      answer: 2,
    },
  ];

  const [current, setCurrent] = useState(0);
  const [select, setSelect] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [status, setStatus] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [showSignUpForm, setShowSignUpForm] = useState(false);

  const calculateResult = () => {
    let temp = 0;
    for (let i = 0; i < questions.length; i++) {
      if (answers[i] && questions[i].answer === answers[i].ans) {
        temp += 1;
      }
    }
    return temp;
  };

  useEffect(() => {
    if (showResult === false) {
      setCurrent(0);
      setSelect(0);
      setAnswers([]);
      setStatus(0);
      setResult(0);
    }
  }, [showResult]);

  useEffect(() => {
    if (select !== 0) {
      const updatedAnswers = [...answers];
      updatedAnswers[current] = { que: current, ans: select };
      setAnswers(updatedAnswers);
    }
  }, [select]);

  const handleNext = () => {
    if (select !== 0) {
      if (current < questions.length - 1) {
        setSelect(0);
        setCurrent(current + 1);
        setStatus(current + 1);
      } else {
        setCurrent(current);
      }
    }
  };

  const handleSubmit = () => {
    if (select !== 0) {
      setStatus(current + 1);
      setShowResult(true);
      setResult(calculateResult());
    }
  };
  const handleCloseModal = () => {
    setShowPopup(false);
    setShowSignUpForm(false);
  };

  const handleYesClick = () => {
    setShowSignUpForm(true);
  };

  const handleNoClick = () => {
    setShowPopup(false);
    setShowSignUpForm(false);
    // setShow(false); 
  };
  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div className="text-center">
        <h3 className="h2 fw-bold mb-3 mt-4">Quiz Game</h3>
        <h5 className="h5  mb-3 mt-2">
          Up for a game on a whole new topic?
          <span
            onClick={handleOpenPopup}
            style={{ cursor: "pointer", color: "blue" }}
          >
            Click Here
          </span>
        </h5>
      </div>
      <section className="d-flex align-items-center justify-content-center flex-column mt-5 mb-5">
        <Progress total={questions.length} current={status} />
        <Quiz
          questions={questions}
          current={current}
          setSelect={setSelect}
          select={select}
          status={status}
        />
        {current < questions.length - 1 ? (
          <button
            className="btn mt-3"
            onClick={handleNext}
            style={{
              borderRadius: "3px",
              backgroundColor: "rgb(252, 169, 3)",
            }}
          >
            Next
          </button>
        ) : (
          <button
            className="btn mt-3"
            onClick={handleSubmit}
            style={{
              borderRadius: "3px",
              backgroundColor: "rgb(252, 169, 3)",
            }}
          >
            Submit
          </button>
        )}
        {showResult && (
          <Result
            result={result}
            setShow={setShowResult}
            setShowPopup={setShowPopup}
          />
        )}
      </section>

      {showPopup && (
        <PopupModal
          onClose={handleCloseModal}
          onYesClick={handleYesClick}
          onNoClick={handleNoClick}
          showSignUpForm={showSignUpForm}
        />
      )}
    </>
  );
}

export default QuizApp;
