import React, { useEffect, useState } from "react";
import "./Quiz.css";

function Quiz({ questions, current, setSelect, select, status }) {
  const [question, setQuestion] = useState(questions[0].question);
  const [options, setOptions] = useState(questions[0].options);
  const [id, setId] = useState(questions[0].id);

  useEffect(() => {
    setQuestion(questions[current].question);
    setOptions(questions[current].options);
    setId(questions[current].id);
  }, [current, questions]);
  return (
    <div className="Quiz p-5">
      <p className="mb-4 fs-5">{question}</p>
      <div className="d-flex align-items-start justify-content-between flex-column">
        <div className="row">
          <div className="col-12">
            <input
              type="radio"
              className="btn-check"
              name={`option${id}`}
              id={`option1${id}`}
              autoComplete="off"
            />
            <label
              className={`custom-btn ${
                select === 1 ? "custom-btn-active" : ""
              }`}
              htmlFor={`option1${id}`}
              onClick={() => setSelect(1)}
            >
              {options[0]}
            </label>
          </div>
          <div className="col-12">
            <input
              type="radio"
              className="btn-check"
              name={`option${id}`}
              id={`option2${id}`}
              autoComplete="off"
            />
            <label
              className={`custom-btn ${
                select === 2 ? "custom-btn-active" : ""
              }`}
              htmlFor={`option2${id}`}
              onClick={() => setSelect(2)}
            >
              {options[1]}
            </label>
          </div>
          <div className="col-12">
            <input
              type="radio"
              className="btn-check"
              name={`option${id}`}
              id={`option3${id}`}
              autoComplete="off"
            />
            <label
              className={`custom-btn ${
                select === 3 ? "custom-btn-active" : ""
              }`}
              htmlFor={`option3${id}`}
              onClick={() => setSelect(3)}
            >
              {options[2]}
            </label>
          </div>
          <div className="col-12">
            <input
              type="radio"
              className="btn-check"
              name={`option${id}`}
              id={`option4${id}`}
              autoComplete="off"
            />
            <label
              className={`custom-btn ${
                select === 4 ? "custom-btn-active" : ""
              }`}
              htmlFor={`option4${id}`}
              onClick={() => setSelect(4)}
            >
              {options[3]}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quiz;
