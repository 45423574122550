import React, { useEffect, useState } from "react";

function Progress({ total, current }) {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth((current / total) * 100);
  }, [total, current]);
  return (
    <div className="Progress">
      <div className="Bar" style={{ width: `${width}%` }}></div>
    </div>
  );
}

export default Progress;
